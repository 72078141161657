@import "~react-image-gallery/styles/css/image-gallery.css";
/* custom font families  */
@font-face {
  font-family: "blanka";
  src: url("../assets/fonts/blanka.otf") format("truetype");
}

:root {
  --max-width : 1280px;
  
  --clr-primary-50: hsla(196, 92%, 47%, 0.2);
  --clr-primary-75: hsla(196, 92%, 55%, 1);
  /* --clr-primary-100: hsla(196, 92%, 47%, 1); */
  --clr-primary-100: hsla(212,94%,45%,1);
  /* --clr-primary-400: hsla(202, 100%, 34%, 1); */
  --clr-primary-400: hsl(212,94%,41%,1);
  --clr-secondary-card-100: rgb(207, 242, 255); /*Made a new color variable for card description background added by imran*/
  --clr-accent-100: hsla(0, 0%, 85%, 1);
  --clr-accent-200: hsla(0, 0%, 53%, 1);

  --clr-dark-900: hsla(0, 0%, 11%, 1);
  --clr-white-900: hsla(100, 100%, 100%, 1);
  --clr-ghost-white: rgb(249 250 250/1);

  --clr-primary-btn: var(--clr-white-900);

  --ff-icon: "Quantico", sans-serif;
  --ff-tag-line: "blanka","Sen";
  --ff-primary: 'Sen', sans-serif;
  /* --ff-primary-bold: "gilory-bold", sans-serif; */
  --ff-secondary:  'Montserrat', sans-serif;

  --ff-body: var(--ff-primary);
  --ff-heading: var(--ff-primary);

  
  --fw-regular: 300;
  --fw-semi-bold: 500;
  --fw-medium-bold: 600;
  --fw-bold: 700;
  --fw-bolder: 800;
  --fw-heading: var(--fw-medium-bold);
  --fs-50: 0.5rem; /*for 8px*/
  --fs-80: 0.8rem; /*for 12.8px*/
  --fs-100: 1rem; /*for 16px*/
  --fs-200: 1.5rem; /*for 24px*/
  --fs-300: 1.75rem; /*for 28px*/
  --fs-500: 2rem; /*for 32*/
  --fs-600: 2.5rem; /*for 40px*/
  --fs-700: 3rem; /*for 48px*/
  --fs-800: 4rem; /*for 64px ! often used in headings*/

  --fs-btn-small: var(--fs-200);
  --fs-btn-medium: var(--fs-300);
  --fs-btn-regular: var(--fs-500);

  --fs-heading-small: var(--fs-500);
  --fs-heading-medium: var(--fs-600);
  --fs-heading-regular: var(--fs-700);

  --size-50: 0.5rem; /*for 8px*/
  --size-100: 1rem;
  --size-200: 1.5rem;
  --size-300: 2rem;
  --size-400: 2.5rem;
  --size-500: 3rem;
  --size-600: 3.5rem;
  --size-700: 4rem;
  --size-800: 4.5rem;
  --size-900: 5rem;
  --size-1000: 7rem;

  --min-width: 19.8125rem; /*317px*/

  /* components based variables  */
  --header-height: 6.25rem;
  --footer-height: 50.125rem; /*530px*/
  --mobile-header-height: var(--size-300);
  --mobile-footer-height: 29.4375rem; /*471px*/
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
* {
  margin: 0;
  padding: 0;
  font: inherit;
}
/* Reset font for all heading tags inside .markdown-wrapper */
.markdown-wrapper h1, .markdown-wrapper h2, .markdown-wrapper h3, .markdown-wrapper h4, .markdown-wrapper h5, .markdown-wrapper h6, .markdown-wrapper p {
  font-family: inherit;
  font-size: revert;
  font-weight: inherit;
  line-height: inherit;
}
/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}
html,
body {
  height: 100%;
}
/* Set core body defaults */
body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture,
svg {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* utility classes */

input[type="checkbox"] {
  opacity: 0;
  position: absolute;

}
input[type="checkbox"]:hover + *::before{
  border-width: 2px;
  
}
input[type="checkbox"] + *::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.625rem;
  margin-bottom: 0.35rem;
  border-radius: 10%;
  border-style: solid;
  border-width: 1px;
  border-color: var(--clr-primary-100);
  text-align: center;
}


input[type="checkbox"]:checked + *::before {
  content: "✓";
  color: var(--clr-primary-100);
  text-align: center;
  border-width: 2px;
  vertical-align: middle;
}

.line-clamp{
  display: -webkit-box;       /* Use a flex container with limited lines */
  -webkit-box-orient: vertical; /* Specify vertical stacking */
  --line-clamp: 3; /* Default value */
  -webkit-line-clamp: var(--line-clamp);
  line-clamp: var(--line-clamp);       /* Limit to 3 lines */
  overflow: hidden;            /* Hide overflow content */
  text-overflow: ellipsis;  

}
/*  --------------------------------- backGrounds---------------------- */
.bg-primaray-100 {
  background-color: var(--clr-primary-100);
}
.bg-primary-400 {
  background-color: var(--clr-primary-400);
}
.bg-accent-100 {
  background-color: var(--clr-accent-100);
}
.bg-accent-200 {
  background-color: var(--clr-accent-200);
}

/* ----------------------------------fonts------------------------  */
.font-bold {
  font-weight: var(--fw-bold);
}
.font-semi-bold {
  font-weight: var(--fw-semi-bold);
}
.font-medium-bold {
  font-weight: var(--fw-medium-bold);
}
.font-regular {
  font-weight: var(--fw-regular);
}

/* ----------------------------------headings-----------------  */
.primary-heading {
  font-size: var(--fs-heading-regular);
  line-height: 3rem;
}
.secondary-heading {
  font-size: var(--fs-heading-medium);
}
.small-heading {
  font-size: var(--fs-heading-small);
}
.regular-heading {
  font-size: 1.5rem;
  line-height: 1.6rem;
  font-weight: bold;
}
.text-medium{
    font-size: 1.5rem;
    line-height: 2rem;
}
.text-regular{
    font-size: 1rem;
    line-height: 1.5rem;
}

.text-regular-forced{
  font-size: 1.3rem !important;
  line-height: 1.3rem !important;
}
.text-grey{
  color: #b7bec5;
}
.text-dark-grey{
    color: rgb(102 102 102/1);
}
.--clr-dark{
  background-color: var(--clr-dark-900);
}

/* -----------------------text----------------  */
.text-bold{
  font-weight: 600;
}
.text-sm {
  font-size: var(--fs-100);
}
.text-md {
  font-size: var(--fs-500);
}
.text-lg {
  font-size: var(--fs-800);
}
.text-center {
  text-align: center;
}
.text-unset{
  text-align: unset !important;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.no-decoration {
  text-decoration: none;
}
.text-black {
  color: var(--clr-dark-900);
}
.text-white {
  color: white;
}
.text-graniteGray {
  --tw-text-opacity: 1;
  color: rgb(102 102 102/var(--tw-text-opacity));
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 500;
}
.text-primary {
  color: var(--clr-primary-100);
}
.text-primary-accent{
  color: var(--clr-accent-200);
}
.object-cover{
  object-fit: cover;
}

/* ---------------------flex---------------  */
.flex {
  display: flex;
}
.flex-wrap{
  flex-wrap: wrap;
}
.block {
  display: block;
}
.absolute{
  position: absolute;
}
.relative{
  position: relative;
}

.overflow-hidden{
  overflow: hidden;
}
.left-0{
  left: 0;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-around{
  justify-content: space-around;
}
.align-center {
  align-items: center;
}
.align-end{
  align-items: flex-end;
}
.align-self-end {
  align-items: self-end;
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-col-reverse {
  flex-direction: column-reverse;
}
.gap-50 {
  gap: var(--fs-50);
}
.gap-100 {
  gap: var(--fs-100);
}
.gap-200 {
  gap: clamp(
    var(--size-200),
    var(--size-400),
    var(--size-700)
  ); /*takes mininum value prefered value or maximum value */
}
.col-gap-200 {
  column-gap: clamp(
    var(--size-200),
    var(--size-200),
    var(--size-700)
  ); /*takes mininum value prefered value or maximum value */
}
.gap-300 {
  gap: clamp(
    var(--size-200),
    var(--size-500),
    var(--size-700)
  ); /*takes mininum value prefered value or maximum value */
}

/* -----------------width and height --------------- */
.w-100 {
  width: 100%;
}
.w-80{
  width: 80%;
}
.w-85{
  width: 85%;
}
.w-90{
  width: 90%;
}
.w-200{
  width: 200%;
}
.w-50{
  width: 50%;
}
.w-30{
  width: 30%;
}
.h-70vh{
  height: 70vh;
}
.w-auto{
  width: auto;
}
.min-w-small {
  min-width: var(--min-width);
}
.max-w{
  max-width: var(--max-width);
}
.h-100 {
  height: 100%;
}
.h-20 {
  height: 20%;
}
.h-900 {
  height: var(--size-900);
}

/* --------------------------margin and paddings----------  */
.my-1{
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.my-1-5{
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.my-2{
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.mt-50 {
  margin-top: var(--size-50);
}
.mt-100 {
  margin-top: var(--size-100);
}
.mt-200 {
  margin-top: var(--size-200);
}
.mt-500 {
  margin-top: var(--size-500);
}
.mt-600 {
  margin-top: var(--size-800);
}
.mb-200 {
  margin-bottom: var(--size-200);
}
.mb-500 {
  margin-bottom: var(--size-300);
}
.mt-300 {
  margin-top: var(--size-300);
}
.mt-400 {
  margin-top: var(--size-400);
}
.ms-500 {
  margin-left: var(--size-500);   /* 3rem */
}
.margin-auto{
  margin: auto;
}
.pt-5 {
  padding-top: var(--size-800);
}
.pt-50 {
  padding-top: var(--size-50);
}

.pt-1-5{
  padding-top: var(--size-300);
}
.pt-2 {
  padding-top: var(--size-400);
}
.pb-800 {
  padding-bottom: var(--size-800);
}
.pb-1000 {
  padding-bottom: var(--size-1000) !important;
}
.p-1 {
  padding: var(--size-100);
}
.p-2 {
  padding: var(--size-300);
}
/* padding 2.5  */
.p-2-5 {
  padding: var(--size-500);
}
.p-50 {
  padding: var(--size-50);

}
.p-3 {
  padding: var(--size-700);
}
.px-50{
  padding-right: var(--size-50);
  padding-left: var(--size-50);
}
.px-1{
  padding-right: var(--size-100);
  padding-left: var(--size-100);
}
.px-2{
  padding-right: var(--size-300);
  padding-left: var(--size-300);
}
.py-50{
  padding-top: var(--size-50);
  padding-bottom: var(--size-50);
}
.py-2{
  padding-top: var(--size-300);
  padding-bottom: var(--size-300);
}
/* -----------------------visibility and opacity------------- */
.z-index-1 {
  z-index: 1;
}
.opacity-half{
  opacity: .5;
}
.hide-overflow {
  overflow: hidden;
}
.show-in-mobile{
  display: none;
}
/* ----------------border-radius-utils----------------------  */
.rounded{
  border-radius: 100%;
}
.br-1{
  border-radius: 1.2rem;
}
.br-2{
  border-radius: 1.5rem;
}
.br-3{
  border-radius: 2rem;
}

/* -----------buttons---------  */
.btn {
  cursor: pointer;
  border-radius: 0.5rem;
  padding: 0.5rem 3.7rem ;
  border: 0;
  font-weight: var(--fw-medium-bold);
  font-size: 1.2rem;
  font-family: var(--ff-body);
  color: var(--clr-primary-btn);
  background-color: var(--clr-primary-100);
  text-decoration: none;
  /* box-shadow: 0 .8em .8em -.8em #ABABAB; */
  white-space: nowrap;
}
.cursor-pointer{
  cursor: pointer;
}
.neutral-btn{
  outline: none;
  background: transparent;
  border: none;
  border-color: none;
}

.mobile-download-btn {
  margin: 0px 0px 0px auto;
  background: transparent;
  text-decoration: none;
  font-weight: var(--fw-semi-bold);
  font-size: 1.2rem;
  color: var(--clr-dark-900);
  font-family: var(--ff-body);
  box-shadow: none;
}
.desktop-download-btn{
  padding: 0.5rem 1.7rem !important;
}
.desktop-download-btn:hover,
  .desktop-download-btn:focus-visible {
    background-color: var(--clr-primary-400);
  }
  
/* custom edit css for image gallery lib / */
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 350px;
  max-width: 100%;
  margin: auto;
  object-fit: contain;
}
.image-gallery-slide{
  padding-right: 3rem;
  padding-left: 3rem;
}
.what-others-say-carousel-arrow {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: var(--fs-500);
  cursor: pointer;
  width: 3.5rem;
  height: 3.5rem;
  background: white;
  box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.25), -4px -4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  text-align: center;
  font-weight: var(--fw-medium-bold);
}
.what-others-say-carousel-arrow .material-symbols-outlineds {
  font-size: 2rem !important;
}

.what-others-say-carousel-arrow.prev {
  top: 55%;
  left: 1%;
  transform: translateY(-50%);
}

.what-others-say-carousel-arrow.next {
  top: 55%;
  right: 1%;
  transform: translateY(-50%);
}
.carousel-bottom-controls > .what-others-say-carousel-arrow{
  position: static;
  transform: translateY(0);
}
.notify-box-blue{
  border: 2px solid var(--clr-primary-75);
  border-radius: .5rem;
  --tw-bg-opacity : 1;
  background-color: var(--clr-primary-50);
}
.notify-box-green{
  border: 2px solid lightgreen;
  border-radius: .5rem;
  --tw-bg-opacity : 1;
  background-color: #cde4cd;
}
.notify-box-green .small-heading p{
  color: rgb(96, 94, 94)
  
}

.notify-box-green button , .notify-box-green a{
  border: 2px solid rgb(37, 203, 37);
  background-color: rgb(113, 215, 113);
}
.notify-box-green button:hover , .notify-box-green a:hover{
  border: 2px solid rgb(37, 203, 37);
  background-color: rgb(105, 197, 105);
}
/* end custom class  */

/* ------------for mobile devices modifications --------------- media queries  */  
/* for1424px  */
@media screen and (max-width: 89em) {
  .section-btn-div {
    column-gap: 2rem;
  }
  .what-others-say-card {
    width: 90%;
    height: 57%;
}
.customer-image {
  height: 1.875rem;
  width: 1.875rem;
}
.what-others-say-carousel-item.active-item .customer-image {
  height: 2.875rem;
  width: 2.875rem;
}
.what-others-say-content {
  max-height: 4rem;
}
.what-others-say-carousel-item.active-item .what-others-say-content {
  max-height: 6rem;
}
.what-others-say-card-customer > a{
  gap: .3rem ;
}
.what-others-say-carousel-item.active-item .what-others-say-card-customer > a{
  gap: var(--fs-50) ;
}
.customer-name h1 {
  font-weight: var(--fw-semi-bold);
  font-size: var(--fs-80);
}
.what-others-say-carousel-item.active-item .customer-name h1 {
  font-weight: var(--fw-semi-bold);
  font-size: var(--fs-100);
}
.what-others-say-carousel-item.active-item > .what-others-say-card {
  height: 77%;
  width: 100%;
}
.what-others-say-carousel {
  padding: 0 1rem;
}
.member-details {
  width: 10rem;
  height: 9rem;
}
.news-carousel-item {
  flex-shrink: unset;
  flex-basis: unset;
  width: 30%;

}
.news-card {
  width: 100%;
}
}
/* for1345 */  
@media screen and (max-width: 84em) {
  .regular-heading{
    font-weight: 500;
  }
  
  .horizontal-scrolable-div{
    margin-left: 0;
    margin-right: 0;
  }
  .awards-cards {
    height: 340px;
    width: 395px;
    min-width: 395px;
    padding: 1rem;
    margin-left: 1rem;
  }
 
  
}

/* for1184px  */
@media screen and (max-width: 74em) {

  .primary-nav-tab{
    width: 35%;
  }
  footer {
    height: unset;
  }
  .footer-nav-sec  nav  ul li a {

    font-size: 1.2rem;
}
.footer-logo-name > h1 {
  font-size: var(--fs-200);
  line-height: var(--size-200);
  font-weight: 400;
}
  .two-col-grid {
    display: flex;
    justify-content: space-around;
  }

  .hero-section-btns{
    padding: 0.7rem 1.7rem !important;
  }
  .section-btn {
    font-size: 1rem;
  }
  .content > h1 {
    font-size: 2.3rem;
    line-height: var(--size-500);
    color: var(--clr-dark-900);
  }

  .what-others-say-carousel-item.active-item > .what-others-say-card {
    height: 70%;
}


.partners-img {
  width: 7rem;
  height: 7rem;
}
.partners-partner-div {
  gap: 2rem 5rem;
}
.footer-logo-image {
  width: 85%;
  height: 69%;
}
.contact-div {
  column-gap: 1rem;
}
.location-section {
  width: 35%;
}

}
/* for912px  */
@media screen and (max-width: 57em) {
  .content > p {
    font-size: 1.1rem;
}
  .bene-cards{
    max-width: unset !important;
    min-width: unset !important;
    width: 100%;

  }
  .bene-cards-wrapper{
    gap: .5rem;

  }
  

  .partner-img{
    width: 125px;
  }
  
.mobile-download-btn {
  font-size: var(--fs-100);
  margin: 0px 0.5rem 0px auto;
  padding: 0.4rem 2.7rem !important;
}
  .mobile-download-btn:hover {
    background-color: unset;

  }
  .content > h1 {
    font-size: 1.6rem;
    font-family: var(--ff-primary-bold);
    line-height: var(--size-300);
    color: var(--clr-dark-900);
    margin-bottom: 0;
  }
  .content > h2 {
    font-size: var(--size-200);
    font-weight: var(--fw-semi-bold);
}
  .footer-icons-div > ul > li > a > span > img { 
    display: inline;
    height: 2.5rem;
    width: 2.5rem;
  }
  .footer-icons-div > ul > li > a > span{ 
    display: inline;
    height: 2.5rem;
    width: 2.5rem;
  }
  .footer-div {
    position: relative;
    flex-flow: column;
    justify-content: center;
}
.social-icons-sec {
  padding: 0 2rem;
  position: absolute;
  right: 0;
  top: 2rem;
}
.two-col-grid {
  justify-content: space-between;
}
.footer-nav-sec nav:last-child {
  margin-right: 0;
  text-align: right;
}
.footer-div {
  gap: 2rem;
}
.footer-nav-sec  nav  ul li a {
  font-weight: 300;
  line-height: var(--size-300);
}
.footer-icons-div > ul {
  gap: .8rem;
}
.download-app-sec {
  align-items: start;
}
.footer-logo-image {
  width: 50%;
}
.footer-nav-sec {
  padding: 0 .9rem;
}

  .what-others-say-carousel-item {
    flex-basis: 100%;
    padding: 0 var(--size-100);
    height: 100%;
}
.what-others-say-carousel-body {
  position: static;
  height: 27rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.what-others-say-carousel-arrow {
  position: absolute;
  border: none;
  font-size: var(--fs-300);
  cursor: pointer;
  width: var(--size-300);
  height: var(--size-300);
  background: #ffffff;
  box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.25), -4px -4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  text-align: center;
  font-weight: var(--fw-medium-bold);
}
.what-others-say-carousel-item > .what-others-say-card {
  border-radius: var(--size-300);
  height: 90% !important;
  background-color: var(--clr-white-900);
  box-shadow: 4px 4px 13px rgba(0, 0, 0, 0.25);
}
.what-others-say-carousel {
  margin: 0 auto;
  height: 18rem;
}
.what-others-say-carousel-item.active-item .what-others-say-content p {
  font-size: var(--fs-100);
}
.home-contact {
  width: 93%;
}
.partners-img {
  width: 6rem;
  height: 6rem;
}
.partners-partner-div {
  gap: 2rem 5rem;
}
.member-details {
  width: 8rem;
}
.department-item {
  font-size: var(--fs-80);
}
.department-item.active-department {
  font-size: var(--fs-80);
}
.kumar-div > h2 {
  font-size: 2.7rem;
  font-weight: 600;
}
.news-upper {
  height: 9rem;
}
.contact-div {
  flex-direction: column;
  margin-bottom: var(--size-300);
}
.contact-div > section{
  width: 100% !important;
}

}
/* for848px  */
@media screen and (max-width: 53em){
  .content > p{
    margin: .2rem 0 0 0;
    font-size: 1rem;
  }
  .primary-nav > ul > li >a ,.primary-nav .nav-dropdown-btn {
    font-size: 0.7rem;
}
.nav-drop-parent{
  margin-top: .4rem;
}
  .primary-nav-tab{
    width: 45%;
  }
  header {
    height: var(--mobile-header-height);
    padding: 2rem 1rem;
  }
  .logo-svg {
    height: 2.1875rem;
    width: 2.1875rem;
  }
  .logo-name > h1 {
    font-size: 1.2rem;
    margin: 0;
}
.logo-name > h1::after {
  font-size: .4rem;
}

  .primary-heading {
    font-size: 2rem;
    line-height: 2.4rem;
  }
  .secondary-heading {
    font-size: 1.5rem;
  }
  .small-heading {
    font-size: 1rem;
  }
  .regular-heading {
    font-size: 1.5rem;
    line-height: 1.7rem;
}
  .text-medium{
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .notify-box{
    padding: .75rem;
  }
  .notify-box p{
      font-size: .875rem;
      line-height: 1.25rem;
  }
  .notify-box button{
      padding-top: .5rem;
      padding-bottom: .5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      font-size: .75rem;
      line-height: 1rem;
  }
  .bene-cards{
    width: 100%;
  }
  .bene-card-wrapper{
    flex-direction: column;
  }
  #bene-container{
      padding-bottom: 3rem;
      padding-top: 2rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
  }
}
/* for755px  fortabs*/
@media screen and (max-width: 48em) {
  .nav-dropdown{
    position: relative;
    transform: scale(1);
    pointer-events: auto;
    opacity: 1;
    margin-left: 1.5rem;
    padding: .5rem .5rem !important;
  }
  .img-span > span{
    display: flex;
  }
  #contactus{
    padding: 0 .5rem;
  }
  .contactFormTitle{
    padding: .5rem 0.5rem;
  }
  .partner-img{
    width: 100px;
  }
  .primary-nav-tab{
    width: 55%;
  }
  .image-on-right .section-content {
    flex-flow: column;
  }
  .image-on-left .section-content {
    flex-flow: column-reverse;
  }
  .image-on-right.image-on-mobile-top .section-content{
    flex-flow: column-reverse !important;
  }
  .image-on-right.image-on-mobile-bottom .section-content{
    flex-flow: column !important;
  }
  .image-on-left.image-on-mobile-bottom .section-content {
    flex-flow: column-reverse !important;
  }
  .image-on-left.image-on-mobile-top .section-content {
    flex-flow: column !important;
  }
  .home-div > section {
    padding: 0.6rem 0 0 0;
    
  }
  .section-content > div {
    width: 100%;
    text-align: center;
  }
  .content {
    padding: 0 var(--size-100);
  }
  .service-section .content{
    padding: 0;
  }
  .content > h1 {
    font-size: var(--fs-200);
  }
  .content > p {
    font-size: .9rem;
    line-height: 1.5;
}
  .section-btn-div {
    display: flex;
    overflow: hidden;
    width: 100%;
    justify-content: center;
  }
  .section-btn {
    font-size: 1rem;
  }
  .section-image {
    height: 25rem;
    padding: var(--size-100);
  }
  .placeholder-image {
    width: 100%;
  }
  .cart-image{
    height: unset ;
  }
  .section-image span img {
    width: 100%;
  }
  .section-image span  {
    width: 100%;
  }
  .image-on-right .section-image span,
  .image-on-right .section-image .placeholder-image {
    border-top-right-radius: 4rem;
    border-bottom-right-radius: 4rem;
  }
  .image-on-left .section-image span,
  .image-on-left .section-image .placeholder-image {
    border-top-left-radius: 4rem;
    border-bottom-left-radius: 4rem;
  }
  .download-icon-links > a > span img {
    width: 8.9375rem;
    line-height: 1.5rem;
  }
  .download-icon-links > a > span  {
    width: 8.9375rem;
    line-height: 1.5rem;
  }
  .footer-nav-sec nav ul li a {
    font-size: var(--fs-100);
    line-height: 2;
  }
  .home-div > section > h1 {
    font-size: var(--fs-200);
  }
  .services-section > span {
    font-size: var(--fs-80);
  }
  .news-carousel-arrow{
    display: none;
  }
  .news-carousel-navigation{
    display: none;
  }
  .news-carousel-container {
    padding-top: 0;
}
.news-carousel-body {
  position: static;
  height: 100%;
  width: 100%;
  padding: var(--size-100);
}
.news-carousel {
    width: 100%;
    height: 100%;
    flex-direction: column;
    overflow-y: scroll;
}
.news-carousel-item {
  width: 100%;
  height: 9rem;
}

.news-card {
  width: 100%;
}
.news-card {
  flex-direction: row;
  gap: 1rem;
  height: 100%;
}
.news-card-image {
  width: 125px;
  object-fit: cover;
  height: 118px;
}
.news-upper {
  width: 40%;
  height: 100%;
  max-width: 30%;
}
.news-content {
  height: 100%;
  width: 60%;
  overflow: hidden;
}
.news-card-heading {
  margin-top: 0;
  font-size: var(--fs-100);

}
.news-card-summary-container {
  height: 100%;
}
.news-card-summary {
  height: 55%;
  overflow: hidden;
}
.news-card-date {
  font-size: .6rem;
}
.news-carousel-item.active-news > .news-card {
  border-color: silver;
}

.news-carousel::-webkit-scrollbar:vertical {
  width: .3rem;
  display: block;
}

.news-carousel::-webkit-scrollbar-thumb {
  background-color: silver;
  border-radius: 1rem;
  border: 1px solid #ffffff;
}

.news-carousel::-webkit-scrollbar-track {
  border-radius: 4rem;
  margin: 1.5rem 0;
  background-color: #ffffff;
}
.content > h2 {
  margin-top: 0;
  font-size: 1.2rem;
  font-weight: var(--fw-semi-bold);
}

.contact-div {
  padding: 0 1rem;
}
.contact-section div:first-child{
  margin-bottom: 2rem;
}
.location-map {
  margin-top: var(--size-100);
  width: 100%;
  max-width: unset;
}



.partner-placeholder-image {
  height: 100%;
  width: 7rem;
}
.partners-img {
  width: 5rem;
  height: 5rem;
}
.partners-partner-div {
  padding: 0 2rem;
}
/* added by deepak  */
.department-container {
  flex-direction: column;
  border: none;
  width: 100%;
  max-height: unset;
}
.member{
  display: none;
}

.department-panel {
  text-align: left;
  padding: 14px 16px;
}


.department-item.active-department {
  background-color: #09ABE533;

  color: var(--clr-dark-900);
  font-weight: 700;
}

.department-item {
  display: block;
  margin-bottom: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s ease;
  background-color: #09ABE533;
  font-weight: 700;
}

.dropdown-button::after {
  display: block;
  content: "\25BE";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

#dropdown-button {
  display: block;
  padding: 10px;
  background-color: var(--clr-accent-100);
  color: var(--clr-dark-900);
  font-weight: 700;
  cursor: pointer;
}

.member-panel{
  background-color:var(--clr-dark-900);
}
/* end deepak  */
.member-image {
  width: 5rem;
  height: 5rem;
}
.member-name {
  font-size: .8rem;
}
.member-role {
  font-size: .7rem;
}
.why-with-us-card-child {
  flex-basis: calc(50% - 20px);
}
 /* edit by anirudh*/
 .bread {
  width: 100%;
}
.butter {
  margin: auto;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.filterbox {
  margin: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 0.4rem;
  border: none;
  width: 100%;
}
.bread-container1 {
  padding: var(--size-50);
  flex-direction: row;
  flex-wrap: wrap;
}
.bread-container2 {
  padding: var(--size-50);
  flex-direction: row;
  flex-wrap: wrap;
}
.jam {
  width: 100%;
  margin: 1%;
  padding: 1%;
}
.card {
  border: 2px solid;
  border-color: var(--clr-primary-100);
  border-radius: 10px;
  width: 100%;
  text-align: start;
}
.cardbox {
  flex-wrap: nowrap;
  flex-direction: column;
}
.card button {
  background-color: var(--clr-primary-100);
  padding: 5px;
  width: 20%;
  color: white;
  border: none;
  border-radius: 5px;
}
.experienceBox p {
  font-size: 0.9rem;
}
.card h1 {
  font-weight: var(--fw-bolder);
  margin-bottom: 0.6rem;
  margin-left: 0.4rem;
  font-size: 1.5rem;
}
/* end anirudh */
.news-carousel {
    gap: .7rem;
    padding: 1rem 0.3rem;
  
}
.news-carousel  .news-card{
  box-shadow: -3px -3px 3px 0px rgba(171, 171, 171, 0.25), 3px 3px 3px 0px rgba(171, 171, 171, 0.25);
}
.show-in-mobile{
  display: block;
}
.hide-in-mobile{
  display: none;
}
.service-section{
  gap: 0 !important;
}
.contact-us-form  input, .contact-us-form > textarea {
  font-size: var(--size-100);
}
.hero-section{
  padding-top: var(--size-400);
}
.footer-heading {
  font-size: var(--fs-200);
  font-weight: var(--fs-semi-bold);
}
.footer-logo-name > h1 {
  font-size: 1.2rem;
  font-weight: var(--fw-bold);
}
.footer-logo-image {
  width: 39%;
}
.mobile-download-btn {
  padding: 0.4rem .7rem !important;
}
.about-page {
  padding: 0 .9rem;
}
.btn {
  padding: 0.9rem 3.7rem ;
}
.blog-card {
  width: 100%;
}
.corporate-section{
  margin-top: .5rem;
}
.footer-copyright {
  font-size: 1rem;

}

}
/* for640px */
@media screen and (max-width: 40em){
  .gallery-wrapper{
    height: 280px;
  }
  .partner-img{
    width: 90px;
  }
  .primary-nav-tab{
    width: 65%;
  }
  .footer-copyright {
    font-size: .9rem;
  
  }
  .partner-placeholder-image {
    height: 5rem;
    width: 5rem;
  }
 
  .partners-partner-div {
    padding: 0 .5rem;
    gap: 1.5rem 2rem;
  }
  .why-with-us-card-container {
    width: 95% !important;
}
.service-page{
  padding: 0 .9rem;
}
.kumar-div > h2 {
  font-size: 2rem;
}
.kumar-div > a{
  padding: .4rem 2rem !important;
}
.kumar-div {
  height: 16rem;
  gap: 5rem;
}
/* .service-section .content > p {
  padding: 0 2rem;
} */
.footer-icons-div{
  margin-top: .4rem;
}
.footer-nav-sec  nav  ul {
  row-gap: .5rem;
}
.download-app-sec > div:first-child{
  width: 32%;
}
.logo-name{
    margin-top: 0.4rem;
}
}

/* for480px formobile*/
@media screen and (max-width: 30em) {
  .what-others-say-carousel-arrow {
    display: none;
}
  .horizontal-scrolable-div{
    gap: 0rem;
  }
  .award-card-body{
    margin-top: .5rem;
  }
  .award-para{
    margin-top: .5rem;
}
.horizontal-scrolable-div::-webkit-scrollbar:horizontal {
  width: 1rem;
  display: block;
}

.horizontal-scrolable-div::-webkit-scrollbar-thumb {
  background-color: silver;
  border-radius: 1rem;
  border: 1px solid #ffffff;
}

.horizontal-scrolable-div::-webkit-scrollbar-track {
  border-radius: 4rem;
  margin: 1.5rem 0;
  background-color: #ffffff;
}
  .regular-heading{
    font-size: 1.2rem;
  }
  .awards-cards {
    margin-left: 1rem;
    margin-right: 1rem;
    height: 270px;
    width: 278px;
    min-width: 278px;
    padding: 1rem;
  }
  .text-graniteGray {
    font-size: .9rem;
    line-height: 1.2rem;
    font-weight: 400;

  }
  .gallery-wrapper{
    height: 200px;
  }
  .nav-dropdown{
    margin-left: 0.5rem;
    padding: .2rem 0 !important;
  }
  .partner-img{
    width: 80px;
  }
  .footer-copyright {
    font-size: .7rem;
  
  }
  .footer-div{
    padding-left: 0;
    padding-right: 0;
  }
  .social-icons-sec {
    right: 1.2rem;
}
  
  .services-section {
    padding-top: var(--size-300) !important;
  }
  .services-section > h1 {
    font-size: var(--fs-300);
  }

  .social-icons-sec {
    padding: 0 !important;
  }
  .qr-container {
    display: none;
  }
  .download-app-sec {
    padding: 0;
  }
  .partners-partner-div {
    padding: 0 1rem;
    gap: 1.5rem 2rem;
}
  .partners-img {
    width: 4rem;
    height: 4rem;
  }
  .why-with-us-card-child {
    flex-basis: 100%;
}
.kumar-div > h2 {
  font-size: 2rem;
}
}
/* for432px  */
@media screen and (max-width: 27em) {
  .section-image {
    height: 17rem;
}
  .section-btn {
    font-size: .9rem;
}
  .partners-partner-div {
    padding: 0.7rem .5rem;
    gap: 1.5rem 2rem;
}
.section-btn-div{
  gap: 0 !important;
  justify-content: space-around  !important;
}
.news-carousel-item {

  height: 7rem;
}
.news-card-summary {
  position: relative;
  content: "Read more";
  visibility: hidden;
}
.news-card-summary::after{
  visibility: visible;
  content: "Read more...";
  position: absolute;
  inset: 0;
  color: var(--clr-primary-100);
}


.footer-icons-div > ul > li > a > span > img {
  display: inline;
  height: 2rem;
  width: 2rem;
}
.footer-icons-div > ul > li > a > span >  {
  display: inline;
  height: 2rem;
  width: 2rem;
}
.footer-div {
  gap: 1rem;
}

}
/* for360px  */
@media screen and (max-width: 22.5em) {
  .gallery-wrapper{
    height: 150px;
  }
  .mobile-download-btn {
    font-size: var(--fs-80);
    margin: 0;
    padding: 0 !important;
    margin-left: 1rem;
}
.section-btn {
  width: 80% !important;
  margin-bottom: .8rem;
}
.section-btn-div {
  flex-flow: column;
  align-items: center;
}

}
/* for353px  */
@media screen and (max-width: 22em) {


  .logo-svg {
    height: 1.575rem;
    width: 1.575rem;
}
.logo-name > h1 {
  font-size: .8rem;
  margin: 0;
}
.logo-name > h1::after {
  font-size: .3rem;
}
.footer-icons-div > ul {
  gap: .2rem;
}
.download-app-sec > div:first-child{
  width: 38%;
}
.footer-icons-div > ul > li > a > span > img {
  height: 1.4rem;
  width: 1.4rem;
}
.footer-icons-div > ul > li > a > span{
  height: 1.4rem;
  width: 1.4rem;
}
}
/* for300.8px  */
@media screen and (max-width: 18.80em) {
  .news-carousel-item {

    height: 5rem;
  }
  .news-carousel-body {
    padding: 0  !important;
}
.home-div > section{
  margin-top: 0 !important;
}
}

/* --------------------------aminations keyframes---------------------  */

.fade-up {
  opacity: 0.5;
  transform: translateY(100px);
  animation: fadeUp 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) forwards;
}

@keyframes fadeUp {
  0% {
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
