.contact-us-form  input, .contact-us-form > textarea {
    width: 100%;
    padding: .6rem 2rem;
    border-radius: .5rem;
    border: 1px solid rgb(196 196 196);
    font-weight: var(--fw-semi-bold);
    font-size: 1.1rem;
    line-height: var(--size-100);
    color: var(--clr-accent-200);
    font-family: var(--ff-secondary);
}
.contact-us-form  input::-webkit-outer-spin-button,
.contact-us-form input::-webkit-inner-spin-button {
        display: none;
      }
.contact-us-form  textarea{
    height: 13rem;
}
.contact-us-form  input:hover,
.contact-us-form  textarea:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.contact-us-form  input:focus,
.contact-us-form  textarea:focus {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    outline: none;
}
.contact-us-form  input::placeholder ,.contact-us-form  textarea::placeholder{
    color: var(--clr-accent-100);
}
.contact-us-btn {
    width: 100%;
    height: 3.5rem;
    margin: 1rem 0;
}
#contactus{
    padding: 0 1.5rem;
}
.contactFormTitle{
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
    text-align: left;
    padding: 1rem 1.5rem;
}
  