.section-with-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.content {
  width: 50%;
  padding: 2rem 0px 0px 3rem;
  background-color: var(--clr-white-900);
}

.content > h1 {
  font-size: 3rem;
  font-family: var(--ff-primary);
  line-height: var(--size-600);
  color: var(--clr-dark-900);
  font-weight: var(--fw-heading);
}
.content > p {
  margin: 1rem .3rem var(--size-400) 0.3rem;
  font-family: var(--ff-secondary);
  font-weight: var(--fw-semi-bold);
  font-size: 1.3rem;
  line-height: var(--size-300);
}
.section-image {
  width: 50%;
  height: 34.125rem;
}
.shimmer{
  height: 400px !important;
}
.placeholder-image {
  background-color: var(--clr-accent-100);
  height: 100%;
  width: 80%;
}
.section-image> span {
  width: 80%;
  height: 100%;
}
.img-span span{
  height: 100%;
  width: 100%;
  
}
.section-image span img {
  width: 80%;
  height: 100%;
}
.image-on-right .section-image>span,
.image-on-right .section-image .placeholder-image {
  float: right;
  border-radius: 4rem 0 0 4rem;
}
.image-on-right .section-image span img{
  float: right;
}
.image-on-left .section-image span,
.image-on-left .section-image .placeholder-image {
  border-radius: 0 4rem 4rem 0;
}

.section-btn-div {
  display: flex;
  column-gap: var(--size-200);
  overflow: hidden;
  flex-wrap: wrap;
  margin-top: var(--size-500);
}
.section-btn {
    padding: 0.4rem 3.7rem !important;
    margin: 0 0 .4rem 0;
}

.section-btn:hover,
  .section-btn:focus-visible {
    background-color: var(--clr-primary-400);
  }
.content h1 ,.content p,.content h2{
    word-wrap: break-word;
    overflow: hidden;
}
.content h2 {
  font-size: var(--size-300);
  font-weight: var(--fw-semi-bold);
}