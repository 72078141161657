#ReactGridGallery{
    display: flex;
    justify-content: center;
}
.gallery-wrapper {
    box-shadow: rgba(0, 0, 0, 0.1) 0px -1px 2px 0px;
    width: 100%;
    height: 350px;
    overflow: hidden;
    border-radius: 2rem;
}
.storiesPage section:not(:first-child){
    margin-top: 1rem;
}
.award-img{
     max-height: 200px;
}
.award-para{
    margin-top: 1rem;
}
.award-card-body{
    margin-top: 1rem;
}
.awards-cards{
    border-radius: 30px;
    height: 350px;
    width: 420px;
    min-width: 420px;
    background-color: var(--clr-ghost-white);
    scroll-snap-align: start;
    overflow: hidden;
}
.horizontal-scrolable-div{
    align-items: center;
    scroll-behavior: smooth;
    overflow-x: scroll;
    display: flex;
    margin-left: 3rem;
    margin-right: 3rem;
    gap: 1rem;
}
.horizontal-scrolable-div::-webkit-scrollbar{
    display: none;
}