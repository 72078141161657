.partners-img {
    width: 10rem;
    height: 10rem;
}

.partners-img > span > img,.partners-img > span, .partner-page-placeholder-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.partners-partner-div{
    flex-wrap: wrap;
    gap: 2rem 5rem;
    padding: 0 3rem;
    justify-content: space-around;
}
.partner-page-placeholder-img {
    background-color: var(--clr-accent-200);
  }