.nav-dropdown{
    background-color: white;
    padding: 2rem 3rem;
    box-shadow: 0px 4px 24px 0px rgba(171, 171, 171, 0.25);
    border-radius: .8rem;
    top: calc(100%);
    transform: scale(0.01);
    transition: opacity 0.2s ease;
    pointer-events: none;
    opacity: 0;
    overflow: hidden;
    /* // 3 times  its parent width  */
    max-width: calc(100%*3);      
    position: absolute;

}
.mobile-nav-list .nav-dropdown{
    padding: .8rem .8rem;
    border-radius: .5rem;
    text-wrap: wrap;
    box-shadow: none;
}
.mobile-nav-list .nav-dropdown > ul > li > a{
    text-wrap: wrap;
}
.sm-mobile-devices .nav-dropdown{
    padding: .8rem .8rem;
    font-size: .2rem;
    border-radius: .3rem;
    box-shadow: none;

}
.nav-dropdown-btn {
    cursor: pointer;
    text-decoration: none;
    font-weight: var(--fw-semi-bold);
    font-size: 1.2rem;
    color: black;
    font-family: var(--ff-secondary);
    display: flex;
    align-items: center;
}
.nav-dropdown-btn::after{
    content: '';
    display: inline-block;
    background: url('../../assets/otherIcons/dropdown.svg') no-repeat center;
    width: 1em;
    height: 1em;
    margin-left: 4px;
}
.mobile-nav-list .nav-dropdown-btn{
    margin-left: 1rem;
    font-family: var(--ff-secondary);
    font-style: var(--ff-body);
    font-size: var(--fs-200);
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    text-decoration: none;
    color: black;
    font-weight: 500;
}

.nav-dropdown-btn:hover {
    color: var(--clr-primary-100);
}
.nav-dropdown-btn:hover .border-line{
    width: 100%;
}
/* .nav-dropdown-btn.active .border-line{
    width: 100%;
} */
.mobile-nav-list > li  .nav-dropdown-txt{
    text-align: left;
    font-family: var(--ff-secondary);
    font-style: var(--ff-body);
    font-size: var(--fs-200);
    font-weight: 500;
}
.nav-drop-parent {
    margin-top: .18rem;
}
.sm-mobile-devices > li  .nav-dropdown-btn{
    margin-left: 0;
}
.nav-dropdown:hover{
    transform: scale(1);
    pointer-events: auto;
    opacity: 1;
}
.nav-dropdown-btn:hover + .nav-dropdown{
    transform: scale(1);
    pointer-events: auto;
    opacity: 1;
}
.mobile-nav-list .nav-dropdown-btn:hover + .nav-dropdown{
    position: relative !important;
}


.nav-dropdown > ul{
    list-style: none;
    gap: .5rem;
    display: flex;
        flex-direction: column;
}
.nav-dropdown > ul > li > a{
    text-decoration: none;
    font-weight: var(--fw-semi-bold);
    color: black;
    font-family: var(--ff-secondary);
    font-size: var(--fs-100);
    text-wrap: nowrap;
}
.nav-dropdown > ul > li{
    text-align: left;
}
.nav-dropdown > ul > li > a:hover{
    color: var(--clr-primary-100);
    
}
.nav-dropdown > ul > li > a:hover ~ .border-line{
    width: 100%;
    
}
.border-line{
    width: 0px;
    height: 2.5px;
    background-color: var(--clr-primary-100);
    transition: width 0.3s ease;

}