.partner-placeholder-image {
  height: 9rem;
  width: 9rem;
  border-radius: 50%;
  background-color: var(--clr-accent-200);
}
.partners-btn{
  padding: 0.5rem 3rem !important;
}

.rfm-child{
  margin-left: 3rem;
}
.partner-img{
  width: 150px;
}