.job-meta-section {
    background-color: var(--clr-primary-50);
    padding-left: var(--size-400);
    padding-right: var(--size-400);
    padding-top: var(--size-400);
    padding-bottom: var(--size-500);
    width: 100%;
    border-radius: 0px 0px 4rem 4rem;
}
.job-meta-section .job-title {
    font-style: normal;
    font-weight: var(--fw-bold);
    font-size: var(--fs-600);
    line-height: var(--size-700);
}
