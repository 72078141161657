#bene-container{
    width: 100%;
    background-color: var(--clr-primary-400);
    padding-bottom: 4rem;
    padding-top: 2rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    max-width: 1111px;
}
.bene-card-img{
    height: 10rem;
}
.bene-cards {
    min-width: 384px;
    max-width: 460px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
#bene-des:hover{
    color: wheat;
}
.bene-card-wrapper{
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;
}