.with-us-title {
    text-align: center;
    padding: 6px;
    background-color: var(--clr-primary-75);
    border-radius: 8px;
    color: white;
    font-weight: var(--fw-bold);
}

.why-us-description {
    background-color: var(--clr-secondary-card-100);
    padding: 0px 16px;
    margin: 1rem 0;
}

.why-with-us-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.why-with-us-card-child {
    flex-basis: calc(33.33% - 20px);
    background-color: var(--clr-secondary-card-100);
    border-radius: 8px;
    padding: 20 0 0 0;
    margin-bottom: 20px;
    box-sizing: border-box;
    font-family: var(--ff-primary);
}

/* career oppertunity added by anirudh   */
.searchbar {
    width: 100%;
    border-radius: 10px;
    padding: 0.65rem;
    border-color: var(--clr-primary-75);
  }
  .searchbarContainer {
    margin: 0.75rem;
    position: relative;
  }
  
  .bread {
    width: 100%;
  }
  .butter {
    margin: auto;
    justify-content: center;
    margin-top: 1rem;
  }
  .filterbox {
    padding: 1rem;
    margin: var(--size-50);
    border-right: 2px solid;
    border-color: var(--clr-accent-200);
  }
  .filterbox:hover {
    padding: 1rem;
    margin: var(--size-50);
    border-right: 2px solid;
    border-color: var(--clr-primary-100);
  }
  
  @media screen and (max-width: 1000em) {
    .butter {
      width: 62rem;
    }
    .filterbox {
      min-width: 17rem;
    }
    .experienceBox p {
      font-size: var(--fs-80);
    }
    .jam {
      width: 100%;
      margin-left: 1rem;
    }
    
  }
  @media screen and (max-width: 64em) {
    .butter {
      width: 55rem;
    }
    .filterbox {
      min-width: 15.7rem;
    }
    .experienceBox p {
      font-size: 0.75rem;
    }
    .jam {
      width: 100%;
      margin-left: 1rem;
    }
  }
  @media screen and (max-width: 57em) {
    .butter {
      width: 55rem;
    }
    .filterbox {
      min-width: 15rem;
    }
    .jam {
      width: 100%;
      margin-left: 1rem;
    }
  }
  @media screen and (max-width: 52em) {
      .butter {
        width: 51rem;
      }
      .filterbox {
        min-width: 13rem;
        padding: 0.5rem;
        margin: 0;
      }
      .jam {
          width: 100%;
          margin-left: 0;
        }
    
    }
  
  .card {
    border: 2px solid;
    border-color: var(--clr-primary-100);
    padding: 0.75rem;
    border-radius: 10px;
    text-align: left;
    flex: 1 1 auto;
  }
  .cardcontainer {
    min-width: 33.33%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    flex: 0 0 auto;
    max-width: 100%;
  }
  .card h1 {
    font-weight: var(--fw-bolder);
    margin-bottom: 0.60rem;
    margin-left: 0.4rem;
  }
  .card h1:hover{
      text-decoration: underline;
      text-decoration-color: var(--clr-primary-75);
  }
  .card button {
    background-color: var(--clr-primary-100);
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    color: white;
    border: none;
    border-radius: 5px;
    width: 5rem;
  }
  .filter {
    font-weight: 900;
    font-size: var(--fs-500);
  }
  .bread-container1 {
    padding: var(--size-50);
  }
  .bread-container2 {
    padding: var(--size-50);
  }
  
  .bread label:hover {
    text-decoration: underline;
    text-decoration-color: var(--clr-primary-75);
  }
  .bread label {
    font-weight: var(--fw-bolder);
    margin-right: 0.625rem;
  }
  .cardbox {
    flex-wrap: wrap;
  }
  .filterClear{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  }
  
  #clear:hover {
    text-decoration: underline;
    text-decoration-color: var(--clr-primary-75);
  }
  .experienceBox {
    margin-bottom: 2rem;
  }
  
  #experienceLevel {
    font-weight: 100;
    float: left;
  }
  #experience {
    font-weight: 600;
  }
  #experienceLevel:hover {
    font-weight: 100;
    float: left;
    text-decoration: underline;
    text-decoration-color: var(--clr-primary-75);
  }
  #experience:hover {
    font-weight: 600;
    text-decoration: underline;
    text-decoration-color: var(--clr-primary-75);
  }
  .morejobs{
      text-align: center;
      margin-bottom: 1rem;
  }
  #moreJobs{
      background-color: var(--clr-primary-100);
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 5px;
      padding-bottom: 5px;
      color: white;
      border: none;
      border-radius: 5px;
      width: 8rem;
  }
  .searchIconBox{
      display: flex;
      position: absolute;
      right: 14px;
      top: 8px;
      width: 2rem;
      height: 2rem;
  }
  #searchIcon{
  width: 100%;
  height: 100%;
  }
 
