.contact-div {
    display: flex;
    justify-content: left;
    padding: 0 3rem;
    column-gap: 6rem;
}
.contact-section{
    width: 80%;
}
.location-section{
    width: 40%;
}
.contact-section small{
    line-height: 1rem;
    font-weight: 300;
}
.address-container {
    width: 96%;
    max-width: 25rem;
}
.address-container >section {
    padding-top: 7.8rem;
}
.address-container section h2 {
    font-weight: bold;
    font-family: var(--ff-primary-bold);
    font-size: 1.3rem;
}
.address-container section small {
    font-size: var(--fs-100);
    font-weight: var(--fw-semi-bold);
    font-family: var(--ff-secondary);
}
.location-map {
    height: 18rem;
    width: 96%;
    margin-top: var(--size-400);
    border-radius: 1rem;
    overflow: hidden;
    max-width: 25rem;
}
.location-map > span{
    width: 100%;
    height: auto;
    object-fit: cover
}
.location-map > span > img{
    width: 100%;
    height: auto;
    object-fit: cover
}
