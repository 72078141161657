.first-solutions-section .section-image .img-span >span > img{
    width: 100%;
}
.full-screen-img .section-image .img-span >span{
    display: flex !important;
    justify-content: center !important;
}
.full-screen-img .section-image .img-span{
    width: 100%;
}
.full-screen-img .section-image .img-span >span > img{
    width: auto;
}
.trigger-scroll-section{
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0px -1px 0px;
}

