footer {
    max-height: var(--footer-height);
    background-color: var(--clr-dark-900);
    /* position: fixed; */
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .footer-logo-name > h1 {
    color: var(--clr-white-900);
    font-family: var(--ff-icon);
    font-weight: var(--fw-bold);
    font-size: var(--fs-500);
    line-height: 1;
  }
  
  .footer-div {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    padding-top: 2rem;
    position: relative;
  }
  
  .download-app-sec {
    padding: 0 var(--size-400) 0 0;
    row-gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.footer-logo-image {
  width: 100%;
  height: 74%;
}
  
 
 
  .download-icon-links > a > span{
    margin-right: 1rem;
    display: inline;
    width: 10.9375rem;
  }
  .download-icon-links > a >span> img{
    margin-right: 1rem;
    display: inline;
    width: 10.9375rem;
  }
  
  .qr-container > span {
    display: inline;
    margin-right: 1rem;
    width: 10.9375rem;
    height: 10.9375rem;
  }
  .qr-container > span > img {
    display: inline;
    margin-right: 1rem;
    width: 10.9375rem;
    height: 10.9375rem;
  }
  
  .footer-div > section {
    flex-grow: 1;
  }
  
  .footer-nav-sec {
    flex-grow: 1;
  }
  
  .social-icons-sec {
    padding: 0 2rem;
  }
  
  .footer-heading {
    font-weight: var(--fw-bold);
    font-size: var(--fs-500);
    line-height: var(--size-600);
    text-transform: capitalize;
    text-align: center;
    color: var(--clr-white-900);
    font-stretch: ultra-expanded;
    font-family: var(--ff-primary-bold);
}
  .footer-nav-sec nav{
    display: inline-flex;
    margin-right: 3rem;
  }
  .footer-nav-sec  nav  ul{
  list-style: none;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  
}
.footer-nav-sec  nav  ul li a {
  font-weight: var(--fw-semi-bold);
  font-size: var(--fs-btn-small);
  line-height: var(--size-400);
  text-transform: capitalize;
  color: var(--clr-white-900);
  text-decoration: none;
  font-family: var(--ff-secondary);
}
.footer-icons-div > ul{
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.footer-icons-div > ul > li > a > span > img{
  display: inline;
  height: 3.5rem;
  width: 3.5rem;

}
.footer-icons-div > ul > li > a > span{
  display: inline;
  height: 3.5rem;
  width: 3.5rem;

}
.primary-nav-mobile.open {
  overflow-y: auto;
}
.footer-div::after{
  content: " ";
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 1.5rem;
  right: 1.5rem;
  height: 1px;

}
.footer-copyright {
  color: var(--clr-white-900);
  text-align: center;
  line-height: 4;
  font-size: 1.5rem;
  font-weight: 500;
}
