
.about-page{
  padding: 0 2rem ;
}
.department-container {
  display: flex;
  padding: 0;
  border: 2px solid;
  border-radius: var(--size-200);
  margin: 25px;
  border-color: var(--clr-primary-75);
  overflow: hidden;
  max-height: 34rem;
}
  
  .department-panel {
    flex: 1;
    padding-right: 0;
    margin-left: 0;
    padding-top: 0;
    margin-top: 0;
    align-items: center;
    text-align: center;
  
  
  }
  
  .department-list {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    
  }
  
  .department-item.active-department {
    background-color: var(--clr-white-900);
    color: var(--clr-primary-75);
    font-weight: var(--fw-bolder);
    font-family: var(--ff-primary-bold);
    font-size: var(--fs-100);
}
.department-item {
  cursor: pointer;
  padding: 10px;
  /* transition: background-color 0.3s ease; */
  background-color: var(--clr-accent-100);
  font-weight: var(--fw-bold);
  line-height: var(--size-400);
  font-size: var(--fs-100);
  white-space: nowrap;
}
  
.member {
  flex: 2 1;
  overflow-y: scroll;
}
.member:-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.member::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.member::-webkit-scrollbar {
  width: 1em;
}
  
  .member-panel{
    display: flex;
    flex-wrap: wrap;
  }
  
  .member-image {
    margin-bottom: 0px;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    align-self: center;
    border-radius: 50%;
    overflow: hidden;
}
  .member-name {
    margin-bottom: 0px;
    align-self: center;
    font-weight: 900;
  }
  
  .member-role {
    margin-bottom: -15px;
    align-self: center;
    margin-bottom: 3px;
  }
  
  .member-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 12rem;
    height: 10rem;
}
  

  
  
.member-name {
  font-weight: var(--fw-medium-bold);
  font-size: var(--fs-100);
  line-height: 2;
}
.member-role {
  font-size: .8rem;
  line-height: 1;
  font-weight: var(--fw-semi-bold);
}
.previous-department{
  border-radius: 0px 0px 1.5rem 0;
  transition: border-radius 0.5s;
}
.next-department{
  border-radius: 0px 1.5rem 0px 0px;
  transition: border-radius 0.5s;
}
.department-item-mobile{
  max-height: 4rem;
  overflow-y: hidden;
  transition: max-height .40s ease-in-out !important;
}
.department-item-mobile.active-department-mobile{
  max-height: 18rem;
  overflow-y: scroll;
  transition: max-height .40s ease-in-out;
}

.department-item-mobile>.department-name-mobile{
  height: 100%;
  align-items: center;
}
.department-name-mobile > .d-name{
  font-size: var(--fs-100);
}
.department-name-mobile > .d-icon {
  font-size: var(--fs-600);
  color: var(--clr-accent-200);
}
.department-members-mobile{
  padding: var(--size-100) var(--size-50);
  display: none !important;
  overflow-x: hidden;
  gap: var(--size-300) var(--size-300);
}

.department-item-mobile.active-department-mobile > .department-members-mobile{
  display: flex !important;
}
.member-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 3 1;
}
.kumar-div{
  height: 27rem;
}
.kumar-div > h2{
    font-size: 3.7rem;
    font-weight: 600;
}