
header {
  position: sticky;
  inset: 0;
  padding: 2rem 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--header-height);
  background-color: var(--clr-white-900);
  box-shadow: 0px 4px 24px 0px rgba(171, 171, 171, 0.25);
  z-index: 999;
}
  
  body{
    font-family: var(--ff-body);
  }
  
  
  .nav-list{
    list-style: none;
  }
  .nav-list > li > a{
    text-decoration: none;
    font-weight: var(--fw-semi-bold);
    font-size: 1.2rem;
    color: black;
    font-family: var(--ff-secondary);
  
  }
  .nav-list > li > a:hover{
    color: var(--clr-primary-100);
  }
  .nav-list > li > a:hover ~ .border-line{
    width: 100%;
    
}
 .logo-svg {
    height: 4rem;
    width: 4rem;
}
.border-line{
  width: 0px;
  height: 2.5px;
  background-color: var(--clr-primary-100);
  transition: width 0.3s ease;

}
  
.logo-name > h1 {
  font-family: var(--ff-icon);
  font-weight: var(--fw-bold);
  font-size: var(--fs-500);
  line-height: 1;
}
  .logo-name > h1::after {
    display: block;
    content: "For You By Me";
    text-align: right;
    color: var(--clr-primary-100);
    font-size: var(--fs-80);
    font-family: var(--ff-tag-line);
    font-weight: var(--fw-regular);
}
  .logo-name{
    display: block;
    height: 100%;
  }
 .hamburger-icon {
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    cursor: pointer;
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  .hamburger-icon.mobile.close{
    margin-left: auto;
  }
  .hamburger-icon.close > span{
    height: 1rem;
  }
  .hamburger-icon.close > span > img{
    height: 1rem;
  }
  
  .line {
    font-weight: var(--fw-bold);
    display: block;
    width: 30px;
    height: 3px;
    background-color: var(--clr-primary-100);
    margin-bottom: 5px;
    transition: transform 0.8s ease;
    border-radius: 2px;
  }
  .line.line-open{
    width: 20px;
    background-color: black;
  }
  .line-open:nth-child(1) {
    transform: rotate(45deg) translate(6px, 6px);
  }
  
  .line-open:nth-child(2) {
    opacity: 0;
  }
  
  .line-open:nth-child(3) {
    transform: rotate(-45deg) translate(6px, -6px);
  }
  
  .primary-nav-mobile {
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(100%);
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 1rem;
    transition: all .5s cubic-bezier(.79,.14,.15,.86)
  }

  .primary-nav-mobile.open {
    transform: translateX(0%);
  }

  .primary-nav-tab{
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(100%);
    width: 25%;
    height: 100%;
    background-color: #fff;
    padding: 1rem;
    transition: all .5s cubic-bezier(.79,.14,.15,.86)
  }

  .primary-nav-tab.open {
    transform: translateX(0%);
  }

  .mobile-nav-list {
    display: flex;
    flex-direction: row;
    flex-direction: column;
    width: 100%;
    /* Add any additional styles for the mobile nav list */
  }
  .mobile-nav-list {
    list-style: none;
    row-gap: 1rem;
    margin-top: 1rem;
}

.mobile-nav-list.tab > li > a{
  margin-left: 1rem;
}
  .mobile-nav-list > li {
    margin: 0 10px; /* Adjust the margin as needed */
  }
  .mobile-nav-list > li > a {
    font-family: var(--ff-secondary);
    font-style: var(--ff-body);
    font-size: var(--fs-200);
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    text-decoration: none;
    color: black;
    font-weight: 500;
}

  /* class for current active navigation  */
  .active {
    color: var(--clr-primary-100) !important;
    padding-bottom: 1px;
    font-weight: var(--fw-medium-bold) !important;
}
.active ~ .border-line{
  width: 100%;
}
.active-mobile{
    font-family: var(--ff-secondary) !important;
    color: var(--clr-primary-100) !important;
  }
  

 /* this is popup css  */
 .popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999;
}
 .popup-mobile {
  position: fixed;
  inset: 10%;
  z-index: 9999999;
}

.popup-img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  border-radius: .5rem;
}
.popup-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #888888;
  z-index: 99999;
  opacity: 0.5;
  pointer-events: none;
}
.popup-close-mobile{
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  cursor: pointer;
  position: fixed;
  background-color: var(--clr-white-900);
  border-radius: 50%;
  right: 15%;
  top: 12%;
  padding: .5rem;
  height: 2rem;
  width: 2rem;
  z-index: 1;
}
.popup-close {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  cursor: pointer;
  position: fixed;
  background-color: var(--clr-white-900);
  border-radius: 50%;
  right: 3%;
  top: 4%;
  padding: .6rem;
  height: 2rem;
  width: 2rem;
  z-index: 1;
}
.popup-close-mobile > span > img{
  width: 100%;
  height: 100%;
}
.popup-close-mobile > span{
  width: 100%;
  height: 100%;
}
.popup-close > span{
  width: 100%;
  height: 100%;
}
.popup-close > span > img{
  width: 100%;
  height: 100%;
}

 /* end popup css  */

 /* -------------------specific media queries for header-----------------  */
 /* for 1242em */
 @media screen and (max-width : 77.6em){
  .primary-nav > ul{
    gap: clamp(
    var(--size-50),
    var(--size-200),
    var(--size-400)
    );
  }
  .primary-nav > ul > li >a{
    font-size: .9rem;
  }
  .primary-nav .nav-dropdown-btn{
    font-size: .9rem;
  }
  .nav-dropdown{
    padding: 1.5rem 1.5rem !important;
  }
  header{
    padding: 2rem;
  }
  .logo-link span{
    transform: scale(0.9); 
    transform-origin: center;
  }
}
 
/* for 991px  */
@media  screen and (max-width : 62em) {
  .primary-nav > ul{
    gap: 1em;
  }
  .logo-link{
    gap: 0rem !important;
  }
  .logo-link span{
    transform: scale(0.8);
    transform-origin: center;
  }
  header{
    padding: 1rem;
  }
 
}