.blog-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.blog-card {
  background-color: var(--clr-white-900);
  padding: var(--size-100);
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
}
  
