/* general css for home elements  */
.home-div > section >h1 {
  font-family: var(--ff-primary);
  font-size: var(--fs-700);
  text-align: center;
  font-weight: var(--fw-heading);
}
.home-div > section {
  padding-top: var(--size-300);
}
.home-div > .services-section {
  padding-top: var(--size-800);
}
/* ---------------------------services section css-----------  */
.cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 20rem;
  }
  .cart-image-section{
    width: 300px;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  .cart-image {

    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 10px;
    height: 14rem;
  }
  
  .cart-placeholder-image {
    width: 300px;
    height: 200px;
    background-color: var(--clr-accent-100);
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .cart-heading {
    font-size: var(--fs-btn-small);
    font-family: var(--ff-primary);
    font-weight: var(--fw-heading);
    margin-bottom: 8px;
  }
  
  .cart-description {
    font-size: 1.1rem;
    margin-bottom: 16px;
    height: 3rem;
    font-family: var(--ff-secondary);
    font-weight: 500;
}
  .service-cart-mobile {
    display: flex;
    justify-content: space-between;
    flex-flow: row;
    gap: 1rem;
    max-width: unset;
    width: 100vw;
}
.service-cart-mobile .cart-image{
    object-fit: cover;
    border-radius: 8px;
    width: 40%;

}
.service-cart-mobile .cart-placeholder-image{
    object-fit: cover;
    border-radius: 8px;
    flex: 40;
    height: 140px;
    width: unset;

}
.cart-content{
  text-align: left;
  flex: 60;
}
.cart-content .service-btn{
  float: left !important;
  font-size: var(--fs-80) !important;
  border-radius: .5rem !important;
  padding: .2rem 2rem !important;
  height: unset !important;
}
.cart-content .cart-heading{
    font-size: var(--fs-100);
    
}

.services-section > h1, span{
  text-align: center;
}

.services-section > span {
  display: block;
  font-size: var(--fs-100);
  font-weight: var(--fw-bold);
  line-height: var(--size-200);
  padding: 0 1.2rem;
}

/* --------------------News section css---------------------  */
.news-card {
  width: 25rem;
  border: 1.2px solid silver;
  border-radius: .8rem;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.news-upper{
  width: 100%;
  height: 12rem;
}
.news-card-image {
  border-radius: 8px;
  object-fit: cover;

}
.news-placeholder-image {

  background-color: var(--clr-accent-100);
  border-radius: 8px;
}

.news-card-heading {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: var(--fs-200);
  font-weight: var(--fw-bolder);
  overflow-wrap: anywhere;
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 5rem;
  font-family: var(--ff-secondary);
  font-weight: var(--fw-heading);
}

.news-card-date {
  font-size: 0.875rem;
  color: #888;
  line-height: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: var(--fw-bold);
  margin-bottom: 2px;
}
.news-card-summary-container {
  height: 11rem;
  overflow: hidden;
}
.news-card-summary {
  font-size: var(--fs-80);
  line-height: 1.4;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 9;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
  font-weight: var(--fw-semi-bold);
  font-family: var(--ff-secondary);
}
.news-carousel-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.news-carousel {
  display: flex;
  margin: auto;
  transition: transform 0.3s ease;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  scroll-padding: 20px;
  scroll-snap-align: center;
  gap: 1.3rem;
  padding: 1rem 1rem;
  justify-content: center;
}
.news-carousel::-webkit-scrollbar {
  display: none;
}

.news-carousel-item {
  flex-shrink: 0;
  flex-basis: 33.33%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.news-carousel-item > .news-card {
  box-shadow: -8px -8px 8px 0px rgba(171, 171, 171, 0.25), 8px 8px 8px 0px rgba(171, 171, 171, 0.25);
}


.news-carousel-navigation {
    display: flex;
    align-items: center;
    width: 100%;
    height: 5rem;
    justify-content: center;

}

.news-carousel-arrow {
  position: absolute;
  border: none;
  font-size: var(--fs-500);
  cursor: pointer;
  width: var(--size-500);
  height: var(--size-500);
  background: #FFFFFF;
  box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.25), -4px -4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  text-align: center;
  font-weight: var(--fw-medium-bold);
  display: flex;
  justify-content: center;
  align-items: center;
}

.news-carousel-arrow.prev{
  top: 50%;
  left: 3%;
  transform: translateY(-50%);
}
.news-carousel-arrow.next{
  top: 50%;
  right: 3%;
  transform: translateY(-50%);
}

.news-carousel-dots {
  display: flex;
  gap: 1rem;
}

.news-carousel-dot {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 3px solid var(--clr-primary-75);
  cursor: pointer;
}

.news-carousel-dot.active-dot {
  background-color: var(--clr-primary-100);
}
.news-carousel-body{
  position: relative;
}

.services-section > div {
  margin-top: var(--size-100);
}
/* contact us section bottom  */
.home-contact {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6rem;
}

