@media screen and (max-width: 26.3em) {
    #notification-container{
        flex-direction: column !important;

    }
    #notification-container > div{
        width: 100% !important;
    }
    #notification-btn{
        margin-top: 1rem;
        align-items: start;
    }
    #notification-btn a{
        padding: 0.3rem 1rem !important;
        font-weight: var(--fw-small-bold);
        font-size: 1rem;
    }


}
